import React from 'react'
import { useNavigate } from 'react-router-dom';
import UsImage from '../img/why1.webp';
import Skills from '../img/skills1.webp'
import Team1 from '../img/team/team-1.jpg'
import Team2 from '../img/team/team-2.jpg'
import Team3 from '../img/team/team-3.jpg'
import Team4 from '../img/team/team-4.jpg'
import Printer1 from '../img/clients/printer.png'
import Printer2 from '../img/clients/inkjet.png'
import Printer3 from '../img/clients/multifunction-printer.png'
import Printer4 from '../img/clients/3d-printing.png'
import Printer5 from '../img/clients/repair.png'

const Printer = () => {

    const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Here you can add form validation and submission logic if needed
    navigate('/thankyou'); // Redirect to the "Thank You" page
  };


  return (
    <>
    <section id="hero" className="d-flex align-items-center">
  <div className="container">
    <div className="row">
      <div
        className="col-lg-6 d-flex flex-column justify-content-center pt-4 pt-lg-0 order-2 order-lg-1"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        <h1>Expert Printer Repair Services for Home & Office</h1>
        
        <div className="d-flex justify-content-center justify-content-lg-start">
          <a href="tel:+18008945705" className="btn-get-started scrollto">
            Call Now
          </a>
        </div>
      </div>
      {/* Uncomment and update the image path if you want to add an image */}
      {/* <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-in" data-aos-delay="200">
        <img src="/path/to/printer-repair-image.jpg" className="img-fluid animated" alt="Printer Repair Services" />
      </div> */}
    </div>
  </div>
</section>


<section id="about" className="about">
  <div className="container" data-aos="fade-up">
    <div className="row content">
      <div className="col-lg-8">
        <h3>Why Choose IT Assist Experts for Printer Repair Services?</h3>
        <ul>
          <li>
            <i className="ri-check-double-line"></i> 
            **Quick and Reliable Repairs**: IT Assist Experts ensures fast and efficient printer repair services to minimize your downtime and keep your work flowing smoothly.
          </li>
          <li>
            <i className="ri-check-double-line"></i> 
            **Expert Technicians**: Our certified professionals are experienced in handling all major printer brands and models, providing accurate diagnoses and effective solutions.
          </li>
          <li>
            <i className="ri-check-double-line"></i> 
            **Affordable Pricing**: High-quality services at competitive prices to ensure value for money without compromising on the results.
          </li>
          <li>
            <i className="ri-check-double-line"></i> 
            **Preventive Maintenance Plans**: We offer ongoing maintenance to keep your printers in top condition and avoid unexpected breakdowns.
          </li>
        </ul>
      </div>
      <div className="col-lg-4 pt-4 pt-lg-0 d-flex align-items-stretch forms">
        <form onSubmit={handleSubmit} id="contactForm" className="php-email-form">
          <div className="row">
            <h4>Get Your Printer Fixed Today!</h4>
            <p>Starting at just $19/month</p>
          </div>
          <div className="inputBox form-group">
            <input type="text" id="name" placeholder="Your Name..." required />
          </div>
          <div className="inputBox form-group">
            <input type="email" id="emailid" placeholder="Your Email..." required />
          </div>
          <div className="inputBox form-group">
            <textarea id="phone" cols="0" rows="1" placeholder="Your Phone..." required></textarea>
          </div>
          <div className="inputBox">
            <button type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>


<section id="clients" className="clients section-bg">
  <div className="container">
    <div className="section-title">
      <h2>Comprehensive Printer Assistance for All Your Needs!</h2>
    </div>
    <div className="row" data-aos="zoom-in">
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src={Printer1} className="img-fluid" alt="Laser Printers" />
        <p>Laser Printers</p>
      </div>
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src={Printer2} className="img-fluid" alt="Inkjet Printers" />
        <p>Inkjet Printers</p>
      </div>
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src={Printer3} className="img-fluid" alt="Multifunction Printers" />
        <p>Multifunction Printers</p>
      </div>
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src={Printer4} className="img-fluid" alt="3D Printers" />
        <p>3D Printers</p>
      </div>
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src="assets/img/clients/ICON-NETWORK.svg" className="img-fluid" alt="Printer Network Setup" />
        <p>Network Setup</p>
      </div>
      <div className="col-lg-2 col-md-4 col-6 d-flex flex-column align-items-center justify-content-center">
        <img src={Printer5} className="img-fluid" alt="Printer Repairs" />
        <p>Repairs & Maintenance</p>
      </div>
    </div>
  </div>
</section>


    <section id="services" className="services section-bg">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Why Choose Us for Printer Repair?</h2>
    </div>
    <div className="row">
      <div className="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
        <div className="icon-box">
          <img src="assets/img/ICON-TEAM.svg" className="img-fluid" alt="" />
          <h4><a href="">Certified Printer Experts</a></h4>
          <p>Our dedicated team of certified printer technicians ensures reliable repairs and maintenance for all major printer brands and models.</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
        <div className="icon-box">
          <img src="assets/img/ICON-BULLSEYE.svg" className="img-fluid" alt="" />
          <h4><a href="">Advanced Diagnostic Tools</a></h4>
          <p>We use state-of-the-art diagnostic tools to identify and resolve printer issues quickly and effectively, ensuring optimal performance.</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
        <div className="icon-box">
          <img src="assets/img/ICON-AUTOMATION.svg" className="img-fluid" alt="" />
          <h4><a href="">Preventive Maintenance Plans</a></h4>
          <p>Regular maintenance plans to extend the life of your printer and prevent unexpected breakdowns, keeping your work uninterrupted.</p>
        </div>
      </div>
      <div className="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
        <div className="icon-box">
          <img src="assets/img/ICON-RESPONSE.svg" className="img-fluid" alt="" />
          <h4><a href="">Quick On-Site & Remote Support</a></h4>
          <p>Whether it’s on-site service or remote troubleshooting, we respond promptly to minimize disruptions and get your printer back online.</p>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="pricing" className="pricing">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Our Pricing</h2>
      <p>IT Assist Experts offers affordable and flexible printer repair service plans to cater to all your home and business printer needs. Choose the plan that works best for you and enjoy reliable service from our certified professionals.</p>
    </div>
    <div className="row">
      <div className="pricing-title">
        <h2>Choose a plan that best suits your needs</h2>
      </div>
      <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
        <div className="box">
          <h3>Basic Printer Care</h3>
          <h4><sup>$</sup>19<span> per month</span></h4>
          <ul>
            <li><i className="bx bx-check"></i><b>Diagnostics & Repairs:</b><br />Basic troubleshooting and repair for minor printer issues.</li>
            <li><i className="bx bx-check"></i><b>Performance Checkups:</b><br />Regular performance checks to keep your printer running smoothly.</li>
            <li><i className="bx bx-check"></i><b>Unlimited Support:</b><br />Phone, email, and chat assistance for your queries.</li>
          </ul>
          <a href="#" className="subscribe-popup-button buy-btn">Subscribe</a>
        </div>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="200">
        <div className="box featured">
          <h3>Professional Printer Care</h3>
          <h4><sup>$</sup>29.99<span> per month</span></h4>
          <ul>
            <li><i className="bx bx-check"></i><b>Includes all Basic features</b></li>
            <li><i className="bx bx-check"></i><b>Advanced Repairs:</b><br />Support for complex hardware and software issues.</li>
            <li><i className="bx bx-check"></i><b>Preventive Maintenance:</b><br />Regular cleaning and alignment services to prevent issues before they occur.</li>
            <li><i className="bx bx-check"></i><b>Priority Support:</b><br />Faster response times for your service requests.</li>
          </ul>
          <a href="#" className="subscribe-popup-button buy-btn">Subscribe</a>
        </div>
      </div>
      <div className="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="300">
        <div className="box">
          <h3>Enterprise Printer Care</h3>
          <h4><sup>$</sup>49.99<span> per month</span></h4>
          <ul>
            <li><i className="bx bx-check"></i><b>Includes all Professional features</b></li>
            <li><i className="bx bx-check"></i><b>On-Site Support:</b><br />Dedicated on-site service for large offices or businesses.</li>
            <li><i className="bx bx-check"></i><b>Fleet Management:</b><br />Comprehensive management of multiple printers in your business.</li>
            <li><i className="bx bx-check"></i><b>Customized Solutions:</b><br />Tailored service plans to meet the specific needs of your business.</li>
          </ul>
          <a href="#" className="subscribe-popup-button buy-btn">Subscribe</a>
        </div>
      </div>
    </div>
  </div>
</section>


    <section id="team" className="team section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Find out why our customers rate us 5 stars!</h2>
          <p>400k+ Satisfied Customers</p>
          <div className="row">
            <div className="col-lg-4"><img src="assets/img/nps-home.png" className="img-fluid" alt="" /></div>
            <div className="col-lg-6 mt-5">
              <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
                <div className="member-info">
                  <p className="text-dark">"Outstanding experience with your tech team! Your dedication and extensive efforts to resolve our computer issue truly stood out. We're deeply grateful for a company that goes above and beyond to ensure their clients are fully satisfied and operational. A massive thank you!"</p>
                  <h4 className="mt-2">Robert D. James</h4>
                  <span>Mapleton, MN</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="100">
              <div className="pic"><img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>Walter White</h4>
                <p>“Partnering with Comp Care Takers support transformed our tech landscape. Their responsive team and unparalleled expertise have propelled our efficiency, ensuring smooth operations and peace of mind. Truly the best IT decision we’ve made!.”</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-4 mt-lg-0">
            <div className="member d-flex align-items-start" data-aos="zoom-in" data-aos-delay="200">
              <div className="pic"><img src="assets/img/team/team-2.jpg" className="img-fluid" alt="" /></div>
              <div className="member-info">
                <h4>Janet Renolds</h4>
                <p>“Our company has been fortunate to have the excellent services of Comp Care Takers. The team equipped our company with the best tools and resources to be protected and run successfully without interruptions in our daily processes. They are there when we need them the most. Thank you!”</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5">
          <div className="section-title">
            <p>400k+ Satisfied Customers</p>
          </div>
        </div>
      </div>
    </section>

    <section id="why-us" className="why-us section-bg">
  <div className="container-fluid" data-aos="fade-up">
    <div className="row">
      <div className="col-lg-7 d-flex flex-column justify-content-center align-items-stretch order-2 order-lg-1">
        <div className="content">
          <h3>Why Choose IT Assist Experts for Printer Repair?</h3>
          <p>Printers are essential tools for both home and office, but issues like paper jams, connectivity errors, and malfunctioning hardware can disrupt your workflow. That’s where IT Assist Experts come in!</p>
          <p>Here's what we offer:</p>
          <ul>
            <li>Expert troubleshooting and repair for all major printer brands and models.</li>
            <li>On-site and remote support for quick and efficient service.</li>
            <li>Regular maintenance to ensure peak printer performance and longevity.</li>
            <li>Guidance on the best practices for printer usage and care.</li>
            <li>Customized solutions for businesses with multiple printers or advanced needs.</li>
          </ul>
        </div>
      </div>
      <div
        className="col-lg-5 align-items-stretch order-1 order-lg-2 img"
        style={{ backgroundImage: `url(${UsImage})` }}
        data-aos="zoom-in"
        data-aos-delay="150"
      >&nbsp;</div>
    </div>
  </div>
</section>


<section id="skills" className="skills">
  <div className="container" data-aos="fade-up">

    <div className="row">
      <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
        <img src={Skills} className="img-fluid" alt="Printer Repair Services" />
      </div>
      <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
        <h3>Our Expertise in Printer Repair:</h3>
        <p>
          Printers are integral to modern work environments, yet maintaining them can be a challenge. With IT Assist Experts, you gain access to industry-leading expertise to ensure your printers perform at their best.
        </p>
        <p>Here's how we can assist:</p>
        <ul>
          <li>Expand your business offerings with professional printer repair services.</li>
          <li>Act as your primary support for printer troubleshooting and maintenance.</li>
          <li>Collaborate seamlessly with your existing IT team or service providers.</li>
          <li>Provide Tier 1 and Tier 2 printer support solutions for your business needs.</li>
          <li>Deliver tailored maintenance plans for businesses with multiple printers or advanced setups.</li>
        </ul>
      </div>
    </div>

  </div>
</section>


    <section id="services" class="services section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Our Techies Stories</h2>
          
        </div>

        <div class="row">
          <div class="col-xl-3 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div class="icon-box">
              <img src={Team1} class="img-fluid" alt="" />
              <h4><a href="">Ethan Walker</a></h4>
               {/* <p>Expert IT professionals committed solely to your business, ensuring seamless operations, personalized solutions, and rapid tech issue resolutions</p> */}
               
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
            <div class="icon-box">
              <img src={Team2} class="img-fluid" alt="" />
              <h4><a href="">Natalie Mitchell</a></h4>
              {/* <p>Advanced, forward-thinking solutions harnessing the latest technological innovations, driving efficiency, and setting industry standards for tomorrow’s challenges.</p> */}
            
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="300">
            <div class="icon-box">
              <img src={Team3} class="img-fluid" alt="" />
              <h4><a href="">Brandon Hughes</a></h4>
               {/* <p>Streamlined, efficient processes powered by intelligent software, optimizing operations, reducing manual intervention, and ensuring consistent, high-quality outcomes.</p> */}
             
            </div>
          </div>

          <div class="col-xl-3 col-md-6 d-flex align-items-stretch mt-4 mt-xl-0" data-aos="zoom-in" data-aos-delay="400">
            <div class="icon-box">
              <img src={Team4} class="img-fluid" alt="" />
              <h4><a href="">Natalie Mitchell</a></h4>
             {/* <p>Instantaneous support from afar, ensuring rapid solutions, minimizing disruptions, and maintaining peak performance, all at the click of a button.</p> */}
            
            </div>
          </div>

        </div>

      </div>
    </section>

    <section id="faq" className="faq section-bg">
  <div className="container" data-aos="fade-up">
    <div className="section-title">
      <h2>Frequently Asked Questions</h2>
    </div>
    <div className="faq-list">
      <ul>
        <li data-aos="fade-up" data-aos-delay="100">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" className="collapse" data-bs-target="#faq-list-1">
            What types of printers do you repair? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-1" className="collapse show" data-bs-parent=".faq-list">
            <p>We repair all major printer brands, including HP, Canon, Epson, Brother, Samsung, Lexmark, and more. Whether it's inkjet, laser, or multifunction printers, we've got you covered.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="200">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#faq-list-2" className="collapsed">
            Do you offer on-site printer repair services? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-2" className="collapse" data-bs-parent=".faq-list">
            <p>Yes, we provide on-site printer repair services for both homes and businesses. Our technicians can visit your location to diagnose and fix issues quickly and efficiently.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="300">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#faq-list-3" className="collapsed">
            How long does a typical printer repair take? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-3" className="collapse" data-bs-parent=".faq-list">
            <p>Most printer repairs are completed within 24-48 hours. However, the timeframe may vary depending on the complexity of the issue and the availability of replacement parts.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="400">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#faq-list-4" className="collapsed">
            What is the cost of printer repair services? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-4" className="collapse" data-bs-parent=".faq-list">
            <p>The cost of repair depends on the issue and the printer model. We provide an initial diagnosis and offer transparent pricing before proceeding with any repairs.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="500">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#faq-list-5" className="collapsed">
            Do you provide regular printer maintenance services? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-5" className="collapse" data-bs-parent=".faq-list">
            <p>Yes, we offer preventive maintenance plans to keep your printers running smoothly and prevent future issues. This includes cleaning, calibration, and checking for worn-out parts.</p>
          </div>
        </li>
        <li data-aos="fade-up" data-aos-delay="600">
          <i className="bx bx-help-circle icon-help"></i> 
          <a data-bs-toggle="collapse" data-bs-target="#faq-list-6" className="collapsed">
            Can you fix network printing issues? 
            <i className="bx bx-chevron-down icon-show"></i><i className="bx bx-chevron-up icon-close"></i>
          </a>
          <div id="faq-list-6" className="collapse" data-bs-parent=".faq-list">
            <p>Absolutely. We specialize in resolving network printing issues, including Wi-Fi connectivity, printer sharing, and driver conflicts for seamless operation.</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
</section>

    </>
  )
}

export default Printer